<template>
  <div id="app">
    <p>
      <center>
        <b> Enter Voucher / Gift Code below: </b><br /><br />
        <b-form-input v-model="amount" placeholder="Enter Code here..." />
        <br /><br />

        <b-button
          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
          variant="outline-success"
          pill
          @click="getResponse"
        >
          USE NOW
        </b-button>
        
      </center>
      <br><br>
      Note: Vouchers / Gift codes are only valid for 3 months!


    </p>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapMutations } from "vuex";
import VueSweetalert2 from "vue-sweetalert2";
import { BButton } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { BFormInput, BRow, BCol, BFormGroup } from "bootstrap-vue";
export default {
  components: {
    BButton,
    BFormInput,
  },
  directives: {
    Ripple,
  },

  data: () => {
    return {
      amount: "",
    };
  },
  methods: {
    ...mapMutations(["HANDLE_CREDIT"]),
    async getResponse() {
      const response = await axios
        .get(`https://alpha.imeicheck.com/api/deposit/code/${this.amount}`, {
          headers: {
             "Content-type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          if (response.data.status == "success") {
            //    console.log(response.data.status);
          //  this.HANDLE_CREDIT(+this.amount);
           this.HANDLE_CREDIT(+response.data.credit);
          }

          console.log();
          if (response.data.status === undefined) {
            alert("ERROR: Something went wrong! \n Status is undefined ");
            return;
          }
          // use sweet alert
          this.$swal({
            icon: `${response.data.status}`,
            title: `${response.data.title}`,
            showConfirmButton: false,
            showCloseButton: true,
            timer: 2500,
          });
        })

        .catch((error) => {
          console.log(error);
          alert("Something went wrong! \n Please try again or contact Admin!");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  border: 1px dashed;
  padding: 20px;
  background: #161e312b;
  text-align: left;
}
</style>