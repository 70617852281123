<template>
  <div>
    <p>
      <b>INFO: You can pay directly with the card if you do not have the application installed!</b><br><br>
      1. Open the Revolut Application on your phone and scan the code below or tap / click on the barcode.<br><br>
        2. Select as currency - USD and enter the amount you want to send.<br><br>
          3. <b> <font color="red" >In the NOTE box, enter the email address with which you are registered on this website</font> </b> and click send.<br>
          <br><br>
        
     <center>  We will add money in your account soon as possible but can take up to 24h. </center> <br>


       <br>  <center>  <a href="https://revolut.me/radutapmx">
        <img src="/revolutQR.png" alt="Revolut" class="center">
      </a> </center><br>
 Ps: You can send directly to the user:  <b> radutapmx </b><br><br>
 <a href="https://wa.me/353852108011?text=REVOLUT PAYMENT - DONE" target="_blank">WhatsApp Contact Link</a>

    </p>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
p {
  border: 1px dashed;
  padding: 20px;
  background: #161e312b;
  text-align: left;
}
</style>
