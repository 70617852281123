<template>
  <div class="method-stripe">
    <b-input-group prepend="$" class="mb-1">
      <b-form-input
        :placeholder="`Enter Amount ${between.min}$ - ${between.max}$`"
        type="number"
        v-model="amount"
      />
    </b-input-group>
    <p class="text-left">
      Note: you will pay {{ fee }}% <br />
      <span v-if="amount > 0">
        Total: <strong>{{ totalAmount }} $</strong>
      </span>
    </p>
    <div class="text-error" v-if="amount > between.max">
      Can't pay more than {{ between.max }}$
    </div>
    <StripeCheckout
      v-show="amount >= between.min && amount <= between.max"
      :amount="totalAmount"
    />
  </div>
</template>

<script>
import StripeCheckout from "@/components/util/Stripe/StripeCheckout.vue";
import { BInputGroup, BFormInput } from "bootstrap-vue";
import { mapMutations } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  props: ["between"],
  data: () => ({
    amount: "",
    fee: 5, // 5%
  }),
  methods: {
    ...mapMutations(["HANDLE_CREDIT"]),
    complete(amount) {
      this.HANDLE_CREDIT(+amount);
      // notify
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `Deposit Made`,
          icon: "CheckIcon",
          text: `You have deposit ${amount}$`,
          variant: "success",
        },
      });
    },
  },
  computed: {
    totalAmount() {
      return Number(+this.amount + this.amount * (this.fee / 100)).toFixed(2);
    },
  },
  components: {
    StripeCheckout,
    BInputGroup,
    BFormInput,
    ToastificationContent,
  },
};
</script>

<style>
#stripe-element-errors {
  color: #f44336;
  font-size: 18px;
  margin-top: 10px;
}
</style>