<template>
  <div id="paypal-smart"></div>
</template>

<script>
import Vue from "vue";

export default {
  props: ["amount"],
  data: () => ({
    buttons: null,
    loading: false,
    env: process.env.VUE_APP_PAYPAL_MODE,
    paypal: {
      sandbox: process.env.VUE_APP_PAYPAL_SANDBOX_CLIENT_ID,
      production: process.env.VUE_APP_PAYPAL_LIVE_CLIENT_ID,
    },
  }),
  async mounted() {
    await Vue.loadScript(
      `https://www.paypal.com/sdk/js?client-id=${this.clientID}`
    );
    this.loaded = true;
    window.paypal
      .Buttons({
        style: {
          tagline: false,
          fundingicons: "true",
        },
        createOrder: (data, actions) => {
          return actions.order.create({
            purchase_units: [
              {
                description: "Ticket!",
                amount: {
                  currency_code: "USD",
                  value: this.amount,
                },
              },
            ],
          });
        },
        onApprove: async (data, actions) => {
          this.$emit("done", data);
        },
        onError: (err) => {
          console.log(err);
        },
      })
      .render("#paypal-smart");
  },
  computed: {
    clientID() {
      return this.env == "sandbox"
        ? this.paypal.sandbox
        : this.paypal.production;
    },
  },
};
</script>

<style>
</style>