<template>
  <b-alert
    :variant="status == 'faild' ? 'danger' : 'success'"
    :show="status != undefined"
    class="mb-2"
  >
    <div class="alert-body">
      <feather-icon icon="InfoIcon" class="mr-50" />
      {{ status == "faild" ? "Deposit Faild" : "Deposit Success" }}
    </div>
  </b-alert>
</template>

<script>
import { BAlert } from "bootstrap-vue";
export default {
  computed: {
    token() {
      return this.$route.query.token;
    },
    status() {
      const queryS = this.$route.query.status;
      return queryS != undefined
        ? queryS == "success"
          ? "success"
          : "faild"
        : undefined;
    },
  },
  components: { BAlert },
};
</script>

<style>
</style>