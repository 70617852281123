<template>
  <div>
    <stripe-checkout
      ref="checkoutRef"
      mode="payment"
      :pk="stripe_pk"
      :session-id="sessionId"
      @loading="(v) => (loading = v)"
    />
    <b-button
      variant="relief-success"
      class="float-right mt-1"
      @click="submit"
      :disabled="loading"
      block
    >
      <b-spinner v-if="loading" small />
      Pay!
    </b-button>
  </div>
</template>

<script>
import { BButton } from "bootstrap-vue";
import { StripeCheckout } from "@vue-stripe/vue-stripe";
import Deposit from "@/services/deposit.service.js";
export default {
  props: ["amount"],
  data: () => ({
    loading: false,
    stripe_pk: process.env.VUE_APP_STRIPE_PK,
    sessionId: null,
  }),
  methods: {
    async submit() {
      this.loading = true;
      const data = await Deposit.stripeCreateSession({ price: this.amount });
      this.sessionId = data.id;
      // You will be redirected to Stripe's secure checkout page
      this.$refs.checkoutRef.redirectToCheckout(0);
    },
  },
  components: {
    StripeCheckout,
    BButton,
  },
};
</script>