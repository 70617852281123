<template>
  <div v-if="loading" class="loading-overlay">
    <b-spinner></b-spinner>
  </div>
</template>

<script>
export default { props: { loading: { type: Boolean, default: false } } };
</script>

<style lang="scss" scoped>
.dark-layout {
  .loading-overlay {
    background-color: #29314596;
    color: white;
  }
}
.loading-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff73;
}
</style>