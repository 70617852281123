import api from '@axios';

class Deposit {
	// Deposit by using PayPal "CAPTURE ORDER"
	static async paypal(orderID) {
		const { data } = await api.post(`/deposit/paypal/${orderID}`);
		return data;
	}
	// Deposit by using Stripe
	static async stripeVerify(tokenID, bodyData) {
		const { data } = await api.post(`/deposit/stripe/verify/${tokenID}`, bodyData);
		return data;
	}
	// Stripe Generate Session
	static async stripeCreateSession(bodyData) {
		const { data } = await api.post(`/deposit/stripe/generate-session`, bodyData);
		return data;
	}
}

export default Deposit;
