<template>
  <div class="method-paypal">
    <LoadingOverlay :loading="loading" />
    <b-input-group prepend="$" class="mb-1">
      <b-form-input
        :placeholder="`Enter Amount ${between.min}$ - ${between.max}$`"
        type="number"
        v-model="amount"
      />
    </b-input-group>
    <p class="text-left">
      Note: you will pay {{ fee }}% <br />
      <span v-if="amount > 0">
        Total: <strong>{{ totalAmount }} $</strong>
      </span>
    </p>
    <div class="text-error" v-if="amount > between.max">
      Can't pay more than {{ between.max }}$
    </div>
    <PaypalSmart
      v-show="amount >= between.min && amount <= between.max"
      :amount="totalAmount"
      @done="(data) => submit(data.orderID)"
    />
  </div>
</template>

<script>
import LoadingOverlay from "@/components/UI/LoadingOverlay";
import { BInputGroup, BFormInput } from "bootstrap-vue";
import PaypalSmart from "@/components/util/PayPal";
import Deposit from "@/services/deposit.service.js";
import { mapMutations } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  props: ["between"],
  data: () => ({
    loading: false,
    amount: "",
    fee: 5, // 5%
  }),
  methods: {
    ...mapMutations(["HANDLE_CREDIT"]),
    // Submit order ID
    async submit(orderID) {
      this.loading = true;
      try {
        const { amount } = await Deposit.paypal(orderID);
        this.HANDLE_CREDIT(+amount);
        // notify
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Deposit Made`,
            icon: "CheckIcon",
            text: `You have deposit ${amount}$`,
            variant: "success",
          },
        });
      } catch (e) {
        console.log(e);
        alert("Something went wrong, deposit faild");
      }
      this.loading = false;
    },
  },
  computed: {
    totalAmount() {
      return Number(+this.amount + this.amount * (this.fee / 100)).toFixed(2);
    },
  },
  components: {
    LoadingOverlay,
    BInputGroup,
    BFormInput,
    PaypalSmart,
    ToastificationContent
  },
};
</script>

<style lang="scss" scoped>
</style>