<template>
  <section id="depositPage">
      <center> <b-alert variant="success" dismissible show >
      <div class="alert-body">
       <span> 
          Now you can pay with BINANCE PAY!
         </span>
      </div>
    </b-alert></center>
    <b-card class="deposit-card py-5">
      <DepositCallback />
      <b-card-text class="current-text-balance mb-2"> Balance: </b-card-text>
      <b-card-text class="current-balance mb-2"> {{ balance }} $ </b-card-text>
      <b-form-select class="mb-2" v-model="paymentMethod" :options="options" />
      <component
        :style="{ position: 'relative' }"
        :is="paymentMethod"
        :between="between"
      />
    </b-card>
  </section>
</template>

<script>
import {
  BAlert,
  BButton,
  BRow,
  BCol,
  BCard,
  BCardText,
  BFormSelect,
} from "bootstrap-vue";
import MethodPaypal from "./MethodPaypal";
import MethodStripe from "./MethodStripe";
import MethodRevolut from "./MethodRevolut";
import MethodStripeCheckout from "./MethodStripeCheckout.vue";
import MethodUsdT from "./MethodUsdT";
import MethodCode from "./MethodCode";
import { mapGetters } from "vuex";
import DepositCallback from "@/components/deposit/Callback.vue";
import MethodBinancePay from './MethodBinancePay.vue';
export default {
  data: () => ({
    paymentMethod: null,
    options: [
      {
        text: "Select Payment Method :",
        value: null,
      },

      {
        text: "Binance Pay (instant)",
        value: "method-binance-pay",
      },

      {
        text: "Visa / Master Card (instant)",
        value: "method-stripe-checkout",
      },
      // { text: "PayPal", value: "method-paypal" },
      {
        text: "Revolut (or Card) Manual Payment",
        value: "method-revolut",
      },
      {
        text: "Crypto USDT Manual Payment",
        value: "method-usdt",
      },
      {
        text: "Use Voucher / Gift Code Payment",
        value: "method-code",
      },

      
    ],
  }),
  computed: {
    ...mapGetters(["balance", "userGroup"]),
    between() {
      return this.userGroup == "RESELLER"
        ? {
            min: +process.env.VUE_APP_MIN_DEPOSIT_RESELLER,
            max: +process.env.VUE_APP_MAX_DEPOSIT_RESELLER,
          }
        : {
            min: +process.env.VUE_APP_MIN_DEPOSIT,
            max: +process.env.VUE_APP_MAX_DEPOSIT,
          };
    },
  },
  components: {
    BAlert,
    BButton,
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormSelect,
    DepositCallback,
    "method-paypal": MethodPaypal,
    "method-stripe": MethodStripe,
    "method-stripe-checkout": MethodStripeCheckout,
    "method-revolut": MethodRevolut,
    "method-usdt": MethodUsdT,
    "method-code": MethodCode,
    "method-binance-pay": MethodBinancePay
  },
};
</script>

<style lang="scss" scoped>
.pay-method {
  min-height: 180px;
}
.current-text-balance {
  font-size: 20px;
  text-align: center;
}
.current-balance {
  font-size: 28px;
  font-weight: bold;
  text-align: center;
}
.deposit-card {
  justify-content: center;
  align-items: center;
  > .card-body {
    min-width: 35%;
  }
  .card-title {
    text-align: center;
  }
}
</style>
