<template>
  <div>
    <StripeElementCard :pk="stripe_pk" ref="stripeRef" @token="tokenCreated" />
    <b-button
      variant="relief-success"
      class="float-right mt-1"
      @click="submit"
      :disabled="loading"
    >
      <b-spinner v-if="loading" small />
      Deposit
    </b-button>
  </div>
</template>

<script>
import { BButton } from "bootstrap-vue";
import { StripeElementCard } from "@vue-stripe/vue-stripe";
import Deposit from "@/services/deposit.service.js";
import { mapMutations } from "vuex";
export default {
  props: {
    amount: { required: true },
  },
  data: () => ({
    loading: false,
    stripe_pk: process.env.VUE_APP_STRIPE_PK,
  }),
  methods: {
    ...mapMutations(["HANDLE_CREDIT"]),
    submit() {
      this.loading = true;
      this.$refs.stripeRef.submit();
      this.loading = false;
    },
    async tokenCreated(token) {
      this.loading = true;
      try {
        const { amount } = await Deposit.stripeVerify(token.id, {
          amount: this.amount,
          token: token.id,
        });
        this.$emit("done", amount)
      } catch (e) {
        console.log(e);
        alert("Something went wrong, deposit faild");
      }
      this.loading = false;
    },
  },
  components: {
    BButton,
    StripeElementCard,
  },
};
</script>

<style>
</style>